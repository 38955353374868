//Banner Image
import BannerImage from "../assets/images/OneFifty_Neighborhood/Neighborhood-Hero-Replacement.jpeg"
import MobileImage from "../assets/images/OneFifty_Neighborhood/Mobile-image.jpg"

//Section-1
import sec1Image1 from "../assets/images/OneFifty_Neighborhood/section_1_image_1_separate.png";
import sec1Image2 from "../assets/images/OneFifty_Neighborhood/section_1_image_2_separate.png";

//Section-2
import sec2Image from "../assets/images/OneFifty_Neighborhood/section_2.png";

//Section-3
import sec3Image1 from "../assets/images/OneFifty_Neighborhood/section_3_image_1_separate.jpg";
import sec3Image2 from "../assets/images/OneFifty_Neighborhood/section_3_image_2_separate.png";

//Section 4
import sec4Image1 from "../assets/images/OneFifty_Neighborhood/One_Fifty-Neighborhood-Transportation-Replacement-1.jpg";
import sec4Image2 from "../assets/images/OneFifty_Neighborhood/One_Fifty-Neighborhood-Transportation-Replacement-2.jpg";

//Components
import TwoColumn from "../components/TwoColumn";
import Footer from "../components/Footer";
import Banner from "../components/Hero";
import MapContainer from "../components/Map";
import MapLegend from '../components/MapLegend';

//React Imports
import { useState } from "react";
import { Helmet } from "react-helmet";
import QuickNav from "../components/QuickNav";


let QuickLinks = {
    backgroundColor: "white",
    prev: {
        title: "Availabilities",
        url: "/availabilities"
    },
    next: {
        title: "Contact",
        url: "/contact"
    }
}
let footerData = {
    backgroundColor: "white"
}

let BannerData = {
    backgroundImage: BannerImage,
    mobileImage: MobileImage,

    backgroundColor: "normal",
    heading: "Welcome to Bushwick",
    description: "One Fifty is located in Bushwick - a popular neighborhood that provides its residents with a quintessential Brooklyn experience. Situated between Brooklyn’s popular Bed-Stuy, Williamsburg, and Ridgewood neighborhoods, these luxury Bushwick rentals put you in the center of all the action.  An area experiencing a period of rapid growth and revitalization, it features a thriving art, restaurant, and nightlife scene, all set against a backdrop of converted warehouses and industrial buildings. Despite its many recent changes, Bushwick maintains a rich cultural heritage and an unbreakable sense of community that residents will immediately appreciate.",
    color : "green",
}

const twoCoulmnData = [
    {
        index: "0",
        variant: "green",
        heading: "Eateries & Entertainment",
        description: "In Bushwick, you’re at the center of it all. A location with a strong melting-pot mentality, local Taquerias serve up authentic homemade dishes while global restaurants like Ichiran offer Japanese Ramen whenever a craving hits. Along with an array of bars, breweries, and local restaurants, this area is famous for its bustling live music scene. Venues like Elsewhere, Our Wicked Lady, and House of Yes are just moments from your front door, meaning you’ll never run out of places to go or bands to see.",
        images: [sec1Image1, sec1Image2],
        flexDirection: "row",
        hasPricing : false,
    },
    {
        index: "1",
        variant: "brown",
        heading: "Art & Culture",
        description: "Bushwick has quickly become an area popular for its arts scene. The neighborhood’s rich cultural heritage is always on display in the form of street art, graffiti murals, and other public art fixtures. Warehouses converted to art galleries line the city streets. Plus, the nearby Bushwick Collective is a community-centric hangout that combines graffiti, street-art, music, food trucks, and wares from local vendors.",
        images: [sec2Image],
        flexDirection: "row-reverse",
        hasPricing : false,
    },
    {
        index: "2",
        variant: "white-brown",
        heading: "Parks & Greenspaces",
        description: "When you’re looking to relax or stretch your legs in the city, Bushwick is home to great parks with fun playgrounds and expansive greenspaces. Green Central Knoll – located just down the block from One Fifty – features a kid-approved playground, sports fields, and a splash pad open during hot summer months. Nearby is also popular Maria Hernandez Park, an expansive greenspace that comes complete with walking paths, playgrounds, and benches situated under large, shady trees.",
        images: [sec3Image1, sec3Image2],
        flexDirection: "row",
        hasPricing : false,
        },
    {
        index: "3",
        variant: "brown",
        heading: "Transportation",
        description: "Getting around the city is easy when you’re stationed in Bushwick. One Fifty is located just a couple blocks from the Morgan Avenue LTA station, which services the L Train.  This area is also well-served by public transit, including the J, M, and Z trains as well as nine bus routes. It’s central location also allows you to easily explore nearby neighborhoods like Bed-Stuy, Williamsburg, and Ridgewood.",
        images: [sec4Image1, sec4Image2],
        flexDirection: "row-reverse",
        hasPricing : false,
    },
]

let MaplegendData = [
    {
        heading: "Transportation",
        list: [
            {
                place: "L at Morgan Av",
                coords: [-73.93371, 40.7060759]
            },
            {
                place: "L DeKalb Av",
                coords: [-73.918533, 40.703873]
            },
            {
                place: "J M Z Central Av",
                coords: [-73.9273632, 40.6978289]
            },
            {
                place: "J M Z Flushing Av",
                coords: [-73.9412453, 40.7003285]
            },
            {
                place: "b 60 bus",
                coords: [-73.9293664, 40.7028061]
            },
            {
                place: "b 57",
                coords: [-73.9286428, 40.7047701]
            }
        ]
    },
    {
        heading: "Parks and Greenspaces",
        list: [
            {
                place: "Green Central Knoll",
                coords: [-73.9332266, 40.7018454]
            },
            {
                place: "Fermi Playground",
                coords: [-73.9285034, 40.7005044]
            },
            {
                place: "Maria Hernandez Park",
                coords: [-73.9238125, 40.7031875]
            }
        ]
    },
    {
        heading: "Eateries and Entertainment",
        list: [
            {
                place: "Your Way Cafe",
                coords: [-73.9301245, 40.7034267]
            },
            {
                place: "The Little Whiskey",
                coords: [-73.929902, 40.7027829]
            },
            {
                place: "Mika",
                coords: [-73.9319682, 40.7050544]
            },
            {
                place: "Dayglow",
                coords: [-73.9309699, 40.7035439]
            },
            {
                place: "The Narrows",
                coords: [-73.9308091, 40.7040829]
            },
            {
                place: "Elsewhere",
                coords: [-73.923169, 40.709411]
            },
            {
                place: "House of Yes",
                coords: [-73.9236, 40.7068]
            },
            {
                place: "Mao Mao",
                coords: [-73.9334587, 40.695799]
            },
            {
                place: "Gold Sounds Bar",
                coords: [-73.9295445, 40.7026664]
            },
            {
                place: "Clara's",
                coords: [-73.9287163, 40.7026112]
            },
            {
                place: "Eyval",
                coords: [-73.9331596, 40.7041424]
            },
            {
                place: "Bunna Cafe",
                coords: [-73.9279056, 40.7049167]
            },
        ]
    },
    {
        heading: "Art and Culture",
        list: [
            {
                place: "Urban Jungle",
                coords: [-73.9296724, 40.7049811]
            },
            {
                place: "Material World Records & Tapes",
                coords: [-73.9298525, 40.704071]
            },
            {
                place: "Brooklyn Art Cave",
                coords: [-73.936791, 40.6980702]
            },
            {
                place: "The Bushwick Collective",
                coords: [-73.9221845, 40.7075174]
            },
            {
                place: "Mark A. West Jr. Museum & Center for the Arts",
                coords: [-73.9331477, 40.7058633]
            },
            {
                place: "Jack Pelican Presents",
                coords: [-73.9325033, 40.6987781]
            },
            {
                place: "Greater Ridgewood Historical Society",
                coords: [-73.9200508, 40.7109292]
            },
            {
                place: "Kalā Yoga",
                coords: [-73.9284733, 40.7034665]
            }
        ]
    }
]

export default function Neighborhoood() {
    const [poi, setPoi] = useState([])
    const [poiTitle, setPoiTitle] = useState("")
    const [showPopup, setShowPopup] = useState(false);
    const [flyData, setFlyData] = useState({
        point: "",
        condition: false
    });
    return (
        <>
            <Helmet>
                <title>One Fifty | Luxury Bushwick Apartments For Rent - EXR</title>
                <meta name="description" content="Renting an apartment in Bushwick, Brooklyn has never been better. At One Fifty, you’ll be in the center of all the action."/>
                <link disabled href="https://api.tiles.mapbox.com/mapbox-gl-js/v3.1.0/mapbox-gl.css" rel="stylesheet" />
            </Helmet>
            <Banner data={BannerData} />
            {
                twoCoulmnData.map((section, index) => {
                    return <TwoColumn key={index} data={section} />;
                })
            }
            <MapContainer title={poiTitle} coords={poi} showPopup={showPopup} flyData={flyData} setFlyData={setFlyData} />
            <MapLegend data={MaplegendData} setPoi={setPoi} setFlyData={setFlyData} flyData={flyData} setPoiTitle={setPoiTitle} setShowPopup={setShowPopup} />
            <script src="https://api.tiles.mapbox.com/mapbox-gl-js/v3.1.0/mapbox-gl.js"></script>
            <QuickNav links={QuickLinks} />
            <Footer data={footerData} />
        </>
    )
}