import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
export default function TwoColumn({ data }) {

  const {
    heading,
    description,
    images,
    flexDirection,
    variant,
    padding,
    cta,
    hasPricing,
    prices,
    subHeading,
    type,
    ctaImage,
    ctaImgPos,
    contentWidth,
    index,
  } = data;

  const scrollUp = () => {
    window.scrollTo({ top: 0 });
  }

  return (
    <>
      <section
        className={variant + " two-column " + flexDirection}
        style={{
          padding: padding,
        }}
      >
        <div
          className="container"
        >
          <div className="content-image-wrap" style={{maxWidth: contentWidth ? contentWidth : ''}} >
            {subHeading && <span className="sub-heading">{subHeading}</span>}
            <div className="content-wrap">
              <h2 className="heading">{heading}</h2>
              <p className="description">{description}</p>
              {cta && (
                <HashLink smooth className="cta"  aria-label={`Learn more about the ${subHeading}`} to={`${cta}#root`}>
                  Learn More
                </HashLink>
              )}

              {hasPricing && (
                <div className="prices">
                  Starting at ${prices}
                  <HashLink
                    smooth
                    aria-label="Learn more about availabilities"
                    to={`/availabilities?type=${type}#availabilitiesHeading`}
                  >
                    <span>View Availabilities</span>
                  </HashLink>
                </div>
              )}
              { images.length > 1 && ( 
                <div className="swiper-arrows-wrap">
                  <div id={"prev-arrow-" + index} className="swiper-prev-arrow" style={{cursor: 'pointer'}} > &larr; </div>
                  <div id={"next-arrow-" + index} className="swiper-next-arrow" style={{cursor: 'pointer'}} > &rarr; </div>
                </div>
              )}
            </div>
            <Link className={ "cta-svg " + ctaImgPos } to={cta} onClick={scrollUp} dangerouslySetInnerHTML={{ __html: ctaImage }} />
          </div>

          <Swiper
            modules={[Navigation, Pagination, A11y]}
            spaceBetween={50}
            slidesPerView={1}
            navigation={{
              nextEl: `#next-arrow-${index}`,
              prevEl: `#prev-arrow-${index}`,
            }}
            pagination={{ clickable: false }}
          >
            {images.map((image, index) => (
              <SwiperSlide key={index} className="image-wrap">
                <img
                  src={image}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </>
  );
}
