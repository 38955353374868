import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Tour3d from "./Tour3d";

export default function Table({ data }) {
  const filterTypes = [
    { type: "all", value: "all" },
    { type: "studio", value: "studio" },
    { type: "1 bed", value: "1-bed" },
    { type: "2 bed", value: "2-bed" },
    { type: "3 bed", value: "3-bed" },
  ];

  const [checkValue, setCheckValue] = useState("all");
  const [modal, setModal] = useState({ link: "", type: "", show: false });

  const toggleModal = (link, type) => {
    setModal(prev => ({
      link: prev.show && prev.type === type ? "" : link,
      type: prev.show && prev.type === type ? "" : type,
      show: !prev.show || prev.type !== type,
    }));
  };

  const [searchParams] = useSearchParams();
  useEffect(() => {
    const type = searchParams.get("type");
    if (type) setCheckValue(type);
  }, [searchParams]);

  return (
    <>
      <section className="availabilities" id="table">
        <h2 className="availabilities-heading" id="availabilitiesHeading">
          Availabilities
        </h2>
        {data && (
          <div className="filterChoice">
            <div className="container">
              <h1>Residences Types</h1>
              <form>
                {filterTypes.map((option, i) => (
                  <label key={i}>
                    <input
                      type="radio"
                      id={option.type}
                      value={option.value}
                      onChange={(e) => setCheckValue(e.target.value)}
                      checked={option.value === checkValue}
                      content={option.type}
                    />
                    <span>{option.type}</span>
                  </label>
                ))}
              </form>
            </div>
          </div>
        )}
        {data && (
          <div className="table">
            <div className="inner">
              <div className="table-row table-head">
                <div className="residence cell head">Residence</div>
                <div className="bed-bath cell head">Type</div>
                <div className="out-door cell head">Outdoor Space</div>
                <div className="price cell head">Price</div>
                <div className="three-d-tour cell head">3D Tour</div>
                <div className="tour3d cell head">Floor Plans</div>
              </div>
              {data.map((group, index) => {
                const filteredRows = group.filter(row =>
                  checkValue === "all" || checkValue === row.type
                );
                return (
                  filteredRows.length > 0 && (
                    <div className="table-group" key={index}>
                      {filteredRows.map((row, rowIndex) => (
                        <div className="table-row" key={rowIndex}>
                          <div className="residence cell">
                            <span className="mobile">Residence</span>
                            {row.residence}
                          </div>
                          <div className="bed-bath cell">
                            <span className="label mobile">Type</span>
                            {row.bedBath}
                          </div>
                          <div className="out-door cell">
                            <span className="label mobile">Outdoor Space</span>
                            {row.outdoor}
                          </div>
                          <div className="price cell">
                            <span className="label mobile">Price</span>${row.price}
                          </div>
                          <div className="three-d-tour cell">
                            <span className="label mobile">3D Tour</span>
                            <button
                              className="btn"
                              style={{ cursor: "pointer" }}
                              onClick={() => row.tour3dLink && toggleModal(row.tour3dLink, "3D")}
                            >
                              {row.tour3dLink ? "View" : "Coming Soon"}
                            </button>
                          </div>
                          <div
                            className="floorplan cell"
                            onClick={() => row.floorPlan && toggleModal(row.floorPlan, "floorPlan")}
                          >
                            <span className="label mobile">Floor Plan</span>
                            {row.floorPlan ? "View" : "Coming Soon"}
                          </div>
                        </div>
                      ))}
                    </div>
                  )
                );
              })}
            </div>
          </div>
        )}
        {modal.show && (
          <div className="show3d-modal availabilities-modal">
            <div className="inner">
              <div className="wrapper">
                <svg
                  className="close-show3d-modal"
                  onClick={() => setModal({ link: "", type: "", show: false })}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 5L5 19M5 5L19 19"
                    stroke="#ffffff"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
                {modal.type === "floorPlan" ? (
                  <img src={modal.link} alt="floorplan" />
                ) : (
                  <Tour3d link={modal.link} close={() => toggleModal("", "")} />
                )}
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
}
