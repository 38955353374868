import { Link } from "react-router-dom";
import React from "react";

const Banner = ({ data }) => {
  const {
    backgroundImage,
    heading,
    description,
    buttonUrl,
    buttonText,
    color,
    mobileImage
  } = data;

  return (
    <>
      <div className={"banner " + color}>
        <img
        className="background-image"
        src={backgroundImage}
        alt="Background"
      />
      <img
        className="background-image mobile-image"
        src={mobileImage}
        alt="Mobile Background"
      />
        <div className="inner">
          {(heading || description) && (
            <div className="content">
              <h1
                className="heading"
                dangerouslySetInnerHTML={{ __html: heading }}
              />
              <p className="description">{description}</p>
              {buttonUrl && buttonText && <Link to="/">Back to Home</Link>}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Banner;
