export default function SpecialsBanner() {
    return (
        <section className="specials">
            <div className="special-banner">
                <div className="text-wrap">
                    <span className="offer-text">
                        LIMITED TIME OFFER: UP TO 1 MONTH FREE!*
                    </span>
                </div>
            </div>
        </section>
    )
}