import headerLogo from "../assets/images/Logos/header.png";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import emailSvg from "../assets/images/email-svgrepo-com.svg";
import callSvg from "../assets/images/header_telephone.svg";
const links = [
  {
    link: "/#root",
    linkText: "Home",
    type: "hash",
    extraClass: "",
  },
  {
    link: "/residences#root",
    linkText: "Residences",
    type: "hash",
    extraClass: "",
  },
  {
    link: "/amenities#root",
    linkText: "Amenities",
    type: "hash",
    extraClass: "",
  },
  {
    link: "/availabilities#root",
    linkText: "Availabilities",
    type: "hash",
    extraClass: "",
  },
  {
    link: "/neighborhood#root",
    linkText: "Neighborhood",
    type: "hash",
    extraClass: "",
  },
  {
    link: "/contact#root",
    linkText: "Contact",
    type: "hash",
    extraClass: "",
  },
];
const Header = () => {
  const [headerToggle, setHeaderToggle] = useState(false);
  const handleToggle = () => {
    setHeaderToggle(!headerToggle);
  };

  return (
    <header id="header" className={headerToggle ? 'close' : ''}>
      <div className="header-wrap">
        <div className="links" id="header-links">
          <div className="inner">
            <div className={"link-group"}>
              <div className="link-container">
              <div className="btn-wrap">
                  <a className="btn" href="tel:5162120430" aria-label="Call us at 516-212-0430">
                    (516) 212-0430
                  </a>
                  <HashLink className="btn" to="/contact#root" aria-label="Send us an email">
                    Contact
                  </HashLink>
                </div>
                {links.map((link, childIndex) => {
                  return (
                    <>
                      {
                        link.type === "hash" && (
                          <HashLink
                            smooth
                            className={"link"}
                            id={link.extraClass}
                            to={link.link}
                            key={childIndex}
                          >
                            {link.linkImage ?
                              <img src={link.linkImage} alt="" />
                              : link.linkText}
                          </HashLink>
                        )
                      }
                      {link.type === "anchor" && (
                        <a
                          className={"link"}
                          id={link.extraClass}
                          href={link.link}
                          key={childIndex}

                        >
                          {link.linkImage ?
                            <img src={link.linkImage} alt="" />
                            : link.linkText}
                        </a>
                      )
                      }
                      {link.type === 'link' && (
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "link active" : "link"
                          }
                          to={link.link}
                          id={link.extraClass}
                          key={childIndex}

                        >
                          {link.linkImage ?
                            <img src={link.linkImage} alt="" />
                            : link.linkText}

                        </NavLink>
                      )}
                    </>
                  );
                })}
                
              </div>

            </div>
          </div>
        </div>
        <div className="btn-wrap">
          <a className="tel" href="tel:5162120430" aria-label="Call us at 516-212-0430">
            <img src={callSvg} alt="call icon" />
          </a>
          <a className="email" href="/contact" aria-label="Send us an email">
            <img src={emailSvg} alt="contact icon" />
          </a>
          <div className="button-wrap">
            <div className="menu-button" onClick={() => handleToggle()}>
              <div className="menu-icon">
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
        <div className="logo-wrap">
          <Link to="/" aria-label="Homepage">
            <img className="logo" src={headerLogo} alt="" />
          </Link>
        </div>

      </div>
    </header>
  );
};

export default Header;
