export default function AddressComponent(props) {
    return (
        <div {...props}>
            <a href="https://www.google.com/maps/place/150+Noll+St,+Brooklyn,+NY+11206,+USA/@40.7030037,-73.9307691,17z/data=!3m1!4b1!4m6!3m5!1s0x89c25c06ad99f85b:0x795edc0a8fcf7475!8m2!3d40.7030037!4d-73.9307691!16s%2Fg%2F11sv05zgb6?entry=ttu">
              150 Noll St, <br /> Brooklyn, NY 11206, USA
            </a>
            <br />
            <a className="telephone" href="tel:5162120430">
                <strong>CALL/TEXT</strong>
                <br />
                (516) 212-0430
                </a>
        </div>
    )
}