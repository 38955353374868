//Banner
import BannerImage from "../assets/images/OneFifty_HomePage_v1/Home-banner.jpg"
import mobileImage from "../assets/images/OneFifty_HomePage_v1/Home-banner.jpg"

//Components
import Banner from "../components/Hero";
import Footer from "../components/Footer";
import Header from "../components/Header";
import QuickNav from "../components/QuickNav";
import SpecialsBanner from "../components/SpecialsBanner";


let QuickLinks = {
  backgroundColor: 'green',
  prev: {
    title: "Home",
    url: "/"
  },
  next: {
    title: "Contact",
    url: "/contact"
  }
}
let footerData = {
  backgroundColor: "none"
}
let BannerData = {
  backgroundImage: BannerImage,
  mobileImage: mobileImage,
  bannerClass: "error",
  heading: "404",
  description: "This page doesn't exist.",
  buttonUrl: "/",
  buttonText: "Back to Home",
  color: "green",
}

export default function ErrorPage() {
  return (
    <>
      <Header />
      <SpecialsBanner/>
      <Banner data={BannerData} />
      <QuickNav links={QuickLinks} />
      <Footer data={footerData} />
    </>
  );
}